body {
  font-family: Arial;
}

.header {
  background: white;
  border-bottom: 1px solid #e5e5e5;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
}

.header,
.footer {
  width: 100%;
  height: 120px;
}

.header .header-content,
.footer .footer-content {
  max-width: 1140px;
  margin: 0 auto;
}

.header .header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  height: 100%;
}

.menu-toggler,
.small-menu-toggler {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.small-menu-toggler a {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0;
}

.xs-menu-toggler {
  flex-direction: column;
}

.xs-logo {
  width: 120px;
}

.menu-icon-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.header h1 {
  font-size: 20px;
  color: #212121;
  font-weight: normal;
}

.header a,
.footer a,
#modal-description a,
.MuiTypography-root a {
  color: #444;
  text-decoration: none;
}

.header a,
.footer a,
#modal-description a {
  margin-right: 20px;
}

.header a:hover,
.footer a:hover,
#modal-description a:hover,
.MuiTypography-root a:hover {
  text-decoration: underline;
  color: #ff0000;
}

.footer {
  border-top: 1px solid #e5e5e5;
  font-size: 80%;
}

.footer .footer-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.modal-title {
  font-weight: bold;
  padding-bottom: 20px;
}

.modal-description-item {
  padding-bottom: 20px;
}
